import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { config } from 'src/app/configs/config';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {
  constructor(private http: HttpClient) {}

  getDepartments(branchId:any): Observable<any> {
    const url = `${environment.baseURL}${config.partialUrls.GetDepartments}?branchId=${branchId}`;
    return this.http.get(url);
  }

  postDepartments(data: any): Observable<any> {
    const url = `${environment.baseURL}${config.partialUrls.AddDepartmentsAndRooms}`;
    return this.http.post(url, data);
  }
}
