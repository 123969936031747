import { CommonModule, NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterLink, RouterLinkActive, RouterModule } from '@angular/router';
import { FeathericonsModule } from 'src/app/shared/icons/feathericons/feathericons.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ToggleService } from '../header/toggle.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { OrganizationService } from 'src/app/shared/services/org-onboarding-service/organization.service';

@Component({
  selector: 'app-unisidebar',
  standalone: true,
  imports: [
    NgScrollbarModule,
    MatExpansionModule,
    RouterLinkActive,
    RouterModule,
    RouterLink,
    NgClass,
    FeathericonsModule,
    CommonModule,
  ],
  templateUrl: './unisidebar.component.html',
  styleUrl: './unisidebar.component.scss',
})
export class UnisidebarComponent implements OnInit {
  OrgAdminRole: boolean;
  BranchAdminRole: boolean;
  DeptAdminRole: boolean;
  permissions: string[];
  MASTER_REPORT: boolean;
  VIEW_STUDENT_DATA: boolean;
  constructor(
    private toggleService: ToggleService,
    private organizationService: OrganizationService
  ) {
    this.toggleService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });
  }
  ngOnInit(): void {
    this.fetchBranchRolesAndRights();
  }

  // Toggle Service
  isToggled = false;
  toggle() {
    this.toggleService.toggle();
  }

  // Mat Expansion
  panelOpenState = false;
  private fetchBranchRolesAndRights() {
    const branchId = UtilityService.getCurrentBranch();

    if (branchId !== null) {
      this.organizationService.getUserBranchRolesAndRights(branchId).subscribe({
        next: (roles: any[]) => {
          this.permissions = [
            ...new Set(roles.flatMap((item) => item.privilege)),
          ];

          if (this.permissions.length > 0) {
            this.checkPermission();
          }
        },
        error: (error: any) => {
          console.log(error);
        },
      });
    } else {
    }
  }

  checkPermission() {
    if (this.permissions.includes('MASTER_REPORT')) this.MASTER_REPORT = true;
    if (this.permissions.includes('VIEW_STUDENT_DATA')) this.VIEW_STUDENT_DATA = true;
  }
}
