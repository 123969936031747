import { UtilityService } from 'src/app/core/services/utility.service';
import { Component, OnInit } from '@angular/core';
import {
  sidebarAnimation,
  iconAnimation,
  labelAnimation,
} from '../../animations';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterModule,
} from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule, NgClass, NgIf, NgStyle } from '@angular/common';
import { FeathericonsModule } from '../../icons/feathericons/feathericons.module';
import { ToggleService } from '../header/toggle.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { AuthService } from '../../services/auth.service';
import { UserOrganizationBranchDto } from 'src/app/models/user-organization-branch-dto';
import { SharedModule } from '../../shared.module';
import { SessionUserType } from 'src/app/core/models/session-type';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { OrganizationService } from '../../services/org-onboarding-service/organization.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    sidebarAnimation(),
    labelAnimation(),
    iconAnimation(),
    labelAnimation(),
  ],
  standalone: true,
  imports: [
    NgScrollbarModule,
    MatExpansionModule,
    RouterLinkActive,
    RouterModule,
    RouterLink,
    NgClass,
    FeathericonsModule,

    RouterLink,
    RouterLinkActive,
    MatFormFieldModule,
    MatSelectModule,
    CommonModule,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    NgStyle,
  ],
})
export class SidebarComponent implements OnInit {
  isExpanded = true;
  showMenu: string = '';
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;

  sidebarState: any;
  panelOpenState = false;
  userInfo: any;
  userMenu: any;

  sessionUserType: SessionUserType = SessionUserType.None;
  menu: any;

  OrgAdminRole = false;
  BranchAdminRole = false;
  DeptAdminRole = false;
  roles: string[];
  locationPermission: boolean = false;
  message: string = '';
  logoUrl: any;
  isFacilityLogo: boolean = false;


  get orgBranch(): boolean {
    return localStorage.getItem('organizationBranchId') ? true : false;
  }

  constructor(
    private toggleService: ToggleService,
    private router: Router,
    private authService: AuthService,
    private permissionService: PermissionService,
    private organizationService: OrganizationService,
    private sanitizer: DomSanitizer,
  ) {
    this.toggleService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });

    this.sessionUserType = UtilityService.sessionUserType;

    // this.menu = [
    //   // {
    //   //   icon: '/assets/images/sidebar/dashboard.svg',
    //   //   name: 'Appointment',
    //   //   path: `dashboard/doctor`,
    //   // },
    //   // {
    //   //   icon: '/assets/images/sidebar/consulting.svg',
    //   //   name: `Consulting`,
    //   //   // path: 'patient/analysis',
    //   // },
    //   // {
    //   //   icon: '/assets/images/sidebar/schedule.svg',
    //   //   name: `Schedule`,
    //   //   // path: '',
    //   // },
    //   // {
    //   //   icon: '/assets/images/sidebar/history.svg',
    //   //   name: `History`,
    //   //   // path: '',
    //   // },
    // ];

    switch (this.sessionUserType) {
      case SessionUserType.Patient:
        this.menu = [
          {
            icon: '/assets/images/sidebar/dashboard.svg',
            name: 'Home',
            path: `dashboard/patient`,
          },
        ];
        break;

      case SessionUserType.Staff:
        this.menu = [
          {
            icon: '/assets/images/sidebar/dashboard.svg',
            name: 'Appointment',
            path: `dashboard/doctor`,
          },
        ];
        break;

      default:
        this.menu = [];
        break;
    }
  }

  ngOnInit() {
    this.toggle();
    this.userInfo = JSON.parse(UtilityService.getLocalStorage('user_info'));
    let menu = JSON.parse(UtilityService.getLocalStorage('user_menu'));
    this.userMenu = [];
    this.requestLocation();

    // if (UtilityService.getLocalStorage('organizationBranchId')){
    //   this.fetchFacilityLogo();
    // }
  }

  private fetchFacilityLogo(){
    const branchId = UtilityService.getCurrentBranch()??'';
    this.organizationService.GetFacilityLogo(branchId).subscribe({
      next:(response)=>{
        const url = URL.createObjectURL(response);
        this.logoUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        this.checkImageLoaded(url);
        UtilityService.setLocalStorage('branchLogoUrl',url)
      }
    })
  }


  checkImageLoaded(url: string): void {
    const img = new Image();
    img.onload = () => {
      this.isFacilityLogo = true
    };
    img.onerror = () => {
      this.isFacilityLogo = false
    };
    img.src = url;
  }
  

  // Toggle Service
  isToggled = false;
  toggle() {
    this.toggleService.toggle();
  }

  // Mat Expansion
  // panelOpenState = false;

  logout(e: any) {
    e.preventDefault();
    localStorage.clear();
    this.router.navigate(['/auth/doctor/login']);
  }

  patientLogout(e: any) {
    e.preventDefault();
    localStorage.clear();
    this.router.navigate(['/auth/patient/login']);
  }


  hasPermission(permission: string): boolean {
    return this.permissionService.hasPermission(permission);
  }


  requestLocation(): void {
    if (!navigator.geolocation) {
      this.message = 'Geolocation is not supported by your browser.';
      this.locationPermission = false;
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.locationPermission = true;
        this.message = `Location Permission granted.`;
      },
      (error) => {
        // Set locationPermission to false initially as it's common for all error cases
        this.locationPermission = false;

        switch (error.code) {
          case error.PERMISSION_DENIED:
            this.message = 'Permission denied. Please allow location access.';
            break;
          case error.POSITION_UNAVAILABLE:
            this.message =
              'Position unavailable. Please check your device settings.';
            break;
          case error.TIMEOUT:
            this.message = 'Request timed out. Please try again.';
            break;
          default:
            this.message = 'An unknown error occurred.';
        }
      }
    );
  }

  openBrowserSpecificSteps() {
    const userAgent = navigator.userAgent;

    if (userAgent.includes('Chrome')) {
      return 'Enable location: Click the lock icon in the address bar > Site settings > Location > Allow.';
    } else if (userAgent.includes('Firefox')) {
      return 'Enable location: Click the lock icon in the address bar > Permissions > Allow Location Access.';
    } else if (userAgent.includes('Safari')) {
      return 'Enable location: Go to Safari Preferences > Websites > Location > Allow.';
    } else if (userAgent.includes('Edge')) {
      return 'Enable location: Click the lock icon in the address bar > Permissions for this site > Location > Allow.';
    } else {
      return 'Please enable location in your browser settings.';
    }
  }
}
