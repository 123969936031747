import { NgModule } from '@angular/core';
import { NumberOnlyDirective } from './directives/NumberOnlyDirective';
import { CommonModule } from '@angular/common';
import { OnlyAlphabetsDirective } from './directives/AlphabetsOnlyDirective';
import { NoSpaceDirective } from './directives/NoSpaceDirective';
import { AlphaNumericDirective } from './directives/AlphaNumericDirective';
import { PhonePrefixComponent } from './components/prefix/phone-prefix/phone-prefix.component';
import {
  MatError,
  MatFormFieldModule,
  MatPrefix,
} from '@angular/material/form-field';
import { NgOtpInputModule } from 'ng-otp-input';
import { DialogVerification } from './components/dialogs/dialog-verification/dialog-verification.component';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { BranchSelectionComponent } from './components/branch-selection/branch-selection.component';
import { MatSelectModule } from '@angular/material/select';
import { TemperatureDirective } from './directives/TemperatureDirective';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HoursEditorComponent } from './components/hours-editor/hours-editor.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ImageDialogComponent } from './components/image-dialog/image-dialog.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    NumberOnlyDirective,
    OnlyAlphabetsDirective,
    NoSpaceDirective,
    AlphaNumericDirective,
    PhonePrefixComponent,
    DialogVerification,
    TemperatureDirective,
    BranchSelectionComponent,
    HoursEditorComponent,
    ImageDialogComponent,
  ],
  imports: [
    CommonModule,
    MatPrefix,
    NgOtpInputModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatError,
    MatButtonModule,
    MatIcon,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatProgressSpinner,
    MatDialogModule,
  ],
  exports: [
    NumberOnlyDirective,
    OnlyAlphabetsDirective,
    NoSpaceDirective,
    AlphaNumericDirective,
    TemperatureDirective,
    PhonePrefixComponent,
    DialogVerification,
    BranchSelectionComponent,
    HoursEditorComponent,
  ],
})
export class SharedModule {}
