import { CommonModule, NgClass } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { RouterOutlet, RouterLink, RouterLinkActive, ActivatedRoute, Router } from '@angular/router';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { LayoutComponent } from './layout/layout.component';
import { WebpageComponent } from './modules/webpage/webpage.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [NgClass, CommonModule, RouterOutlet, RouterLink, RouterLinkActive, LoaderComponent, LayoutComponent]
})
export class AppComponent {
  title = 'neemandtulsi';
  portal: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }
  ngAfterViewInit(): void {
    const divsWithStyle = document.querySelectorAll('div');
    divsWithStyle.forEach(div => {
      if (div.innerText == 'This application was built using a trial version of Syncfusion Essential Studio. To remove the license validation message permanently, a valid license key must be included. Claim your free account') {
        div.style.display = 'none';
      }
    });
  }

  ngOnInit(): void {
    const subdomain = window.location.hostname.split('.')[0];
    if (subdomain === 'dev-oversight' || subdomain === 'oversight') {
      localStorage.setItem('isUniversityLogin','Y');
    }
    this.portal = true
  }
}
