import { CommonModule, DatePipe, Location, NgClass } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import {
  MatButton,
  MatButtonModule,
  MatIconButton,
} from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatIcon } from '@angular/material/icon';
import { MatNavList } from '@angular/material/list';
import { MatSidenav } from '@angular/material/sidenav';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility.service';
import { SidebarService } from '../../services/sidebar.service';
import { FeathericonsModule } from '../../icons/feathericons/feathericons.module';
import { MatMenuModule } from '@angular/material/menu';
import { ToggleService } from './toggle.service';
import { OrganizationService } from '../../services/org-onboarding-service/organization.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    FeathericonsModule,
    MatButtonModule,
    MatMenuModule,
    RouterLink,
    NgClass,

    CommonModule,
    RouterOutlet,
    FlexModule,
    ExtendedModule,
    MatSidenav,
    MatNavList,
    MatCard,
    MatIconButton,
    MatIcon,
    MatGridList,
    MatGridTile,
    MatButton,
  ],
  providers: [DatePipe],
})
export class HeaderComponent implements OnInit, OnDestroy {
  routeQueryParams$: Subscription;
  public userInfo: any;
  firstLetter: any;
  secondLetter: any;
  profileName: any;

  userType: any;
  logoUrl: any;
  isFacilityLogo: boolean = false;

  constructor(
    private sidebarService: SidebarService,
    private router: Router,
    public dialog: MatDialog,
    private location: Location,
    private route: ActivatedRoute,
    public toggleService: ToggleService,
    private datePipe: DatePipe,
    private organizationService: OrganizationService,
    private sanitizer: DomSanitizer,
  ) {
    this.userType = UtilityService.getLocalStorage('userType');

    //this.userInfo = JSON.parse(localStorage.getItem('user_info') || '');
    this.routeQueryParams$ = route.queryParams.subscribe((params) => {
      if (params['dialog']) {
        // this.handleChangePassword();
      }
    });

    this.toggleService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });
  }

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem('user_info') || '{}');
    this.firstLetter =
      this.userInfo.firstName != null
        ? this.userInfo.firstName
            .split(' ')
            .map((name: any) => name[0])
            .join('')
            .toUpperCase()
        : '';
    this.secondLetter =
      this.userInfo.lastName != null
        ? this.userInfo.lastName
            .split(' ')
            .map((name: any) => name[0])
            .join('')
            .toUpperCase()
        : '';
    this.profileName = this.firstLetter + this.secondLetter;

    this.organizationService.branchSelectionBranchFlag$.subscribe((flag) => {
      if(flag){
        if (UtilityService.getLocalStorage('organizationBranchId')){
          this.fetchFacilityLogo();
        }
      }
    })
  }

  
  private fetchFacilityLogo(){
    const branchId = UtilityService.getCurrentBranch()??'';
    this.organizationService.GetFacilityLogo(branchId).subscribe({
      next:(response)=>{
        const url = URL.createObjectURL(response);
        this.logoUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        this.checkImageLoaded(url);
        UtilityService.setLocalStorage('branchLogoUrl',url)
      }
    })
  }

  checkImageLoaded(url: string): void {
    const img = new Image();
    img.onload = () => {
      this.isFacilityLogo = true
    };
    img.onerror = () => {
      this.isFacilityLogo = false
    };
    img.src = url;
  }
  

  ngOnDestroy() {
    this.routeQueryParams$.unsubscribe();
  }

  // Toggle Service
  isToggled = false;
  toggle() {
    this.toggleService.toggle();
  }

  // Dark Mode
  toggleTheme() {
    this.toggleService.toggleTheme();
  }

  logout() {
    UtilityService.clearLocalStorage();
    this.router.navigate(['/auth/doctor/login']);
  }

  patientLogout() {
    UtilityService.clearLocalStorage();
    this.router.navigate(['/auth/patient/login']);
  }

  // Current Date
  currentDate: Date = new Date();
  formattedDate: any = this.datePipe.transform(
    this.currentDate,
    'dd MMMM yyyy'
  );
}
